import $ from 'jquery-slim';

class Toggle
{
    constructor($element)
    {
        this.$element = $element;

        this.$collapse = $(this.$element.attr('data-unchecked-target'));



        let id = this.$element.attr('for');
        this.$checkbox = $('#' + id);
        this.$checkbox.change(this.change.bind(this));
    }

    change()
    {

        this.$collapse.collapse(this.$checkbox.is(':checked') ? 'hide' : 'show');
    }
}

class OptUncheckedToggle
{
    constructor()
    {
        $(document).ready(this.init.bind(this));
    }

    init()
    {
        let $elements = $('[data-unchecked-target]');

        this.toggles = [];

        for (let i = 0; i < $elements.length; i++) this.toggles.push(new Toggle($elements.eq(i)));
    }

}

const uncheckedToggle = new OptUncheckedToggle();






